import {
    useEffect, useMemo, useState,
} from 'react';
import {useLazyQuery, useQuery, useReactiveVar} from '@apollo/client';
import store from 'store/dist/store.modern';
import {PageProps} from 'gatsby';
import {ActionButtons, Layout} from '../../../components/layout';
import {Button, Tabs} from '../../../components/core';
import FullFilter from '../../../components/modules/fullFilter/fullFilter';
import useChannelTabsData, {ChannelTabsVariant} from '../../../hooks/useChannelTabsData';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useFilter from '../../../hooks/useFilter';
import {Filter, FilterDimensionsData} from '../../../interfaces/filters';
import {
    GetPlaylistFiltersDocument,
    GetPlaylistFiltersQuery,
    GetPlaylistsDocument,
    OrderWay,
    PlaylistOrderBy,
} from '../../../graphql-types';
import getFilterAttributes from '../../../helpers/filters/getFilterAttributes';
import {accountVar} from '../../../helpers/graphql/variables';
import {PlaylistModalVariants} from '../../../configs/pages/playlists/modals';
import {ColumnSortingActive} from '../../../interfaces/table';
import {UserApplicationScopes} from '../../../interfaces/applications';
import {AddIcon} from '../../../components/core/icons';
import AddPlaylistModal from '../../../modals/playlists/addPlaylist';
import {tablePlaylistsMainColumns} from '../../../configs/pages/playlists';
import Table from '../../../components/core/table/table';
import TableFooter from '../../../components/core/table/tableFooter';
import PlaylistExportModal from '../../../modals/playlists/playlistExport';
import toastify, {ToastLocationState} from '../../../helpers/toast/toastify';

type ChannelPlaylistsPageType = {
    channel: string,
};

type ChannelPlaylistsPageTypeProps = PageProps<null, null, ToastLocationState> & ChannelPlaylistsPageType;

const ChannelPlaylistsPage = ({channel, location}: ChannelPlaylistsPageTypeProps) => {
    const account = useReactiveVar(accountVar);
    const [paging, setPaging] = useState({
        limit: store.get('userSettings')?.showPerPage || 10,
        page: 1,
    });
    const [openedModal, setOpenedModal] = useState<PlaylistModalVariants | null>(null);
    const channelTabsData = useChannelTabsData(ChannelTabsVariant.playlists);
    const [localStorageFilters, setLocalStorageFilters] = useLocalStorage<Filter | null>('filterPlaylistsTable');
    const [searchQuery, setSearchQuery] = useState('');
    const {data: playlistFiltersData} = useQuery(GetPlaylistFiltersDocument);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [activeSorting, setActiveSorting] = useState<ColumnSortingActive<PlaylistOrderBy>>({});
    const [playlistToExport, setPlaylistToExport] = useState<{id: string; name: string | undefined; videosCount: number} | null>(null);

    const hasPlaylistWritePermission = useMemo(() => (
        account ? (account.permissions.playlists?.write || false) : false
    ), [account]);

    const tableColumns = tablePlaylistsMainColumns({
        channel,
        sorting: {
            enabled: [PlaylistOrderBy.Name, PlaylistOrderBy.YoutubeId],
            active: activeSorting,
            setSorting: setActiveSorting,
        },
        setPlaylistToExport,
        setOpenedModal,
        hasPlaylistWritePermission,
    });
    const {
        filters,
        loaded: filtersLoaded,
        toggleFilter,
        setFilter,
        setAllFilters,
    } = useFilter(localStorageFilters || undefined);

    const getQueryVariables = (resetPaging?: boolean) => ({
        paging: resetPaging ? {limit: paging.limit, page: 1} : paging,
        filter: {
            channelIds: [channel],
            languageIds: filters.languages as string[],
            themeIds: filters.themeAttributes as string[],
            fulltext: searchQuery,
        },
        order: {
            by: activeSorting.column,
            way: activeSorting.desc ? OrderWay.Desc : OrderWay.Asc,
        },
    });

    const [
        fetchPlaylists, {
            data,
            error,
            loading,
        },
    ] = useLazyQuery(GetPlaylistsDocument, {
        fetchPolicy: 'cache-and-network',
    });
    const accountPermissions = account ? account.permissions : {} as UserApplicationScopes;

    const handleApplyFilters = (): void => {
        setLocalStorageFilters(filters);

        fetchPlaylists({variables: getQueryVariables(true)});
    };

    useEffect(() => {
        if (!filtersLoaded) {
            return;
        }

        fetchPlaylists({variables: getQueryVariables(true)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersLoaded]);

    useEffect(() => {
        fetchPlaylists({variables: getQueryVariables(true)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchQuery,
        channel,
        activeSorting.column,
        activeSorting.desc,
    ]);

    useEffect(() => {
        fetchPlaylists({variables: getQueryVariables()});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    const playlistFilters = useMemo(() => {
        if (!playlistFiltersData) {
            return {};
        }

        return getFilterAttributes<GetPlaylistFiltersQuery>(playlistFiltersData);
    }, [playlistFiltersData]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title="Playlist manager"
                scope="playlists"
            >
                <Tabs data={channelTabsData}/>
                <ActionButtons buttonsHidden={filtersOpen}>
                    {accountPermissions.playlists?.write && (
                        <Button
                            onClick={() => setOpenedModal(PlaylistModalVariants.add)}
                            text="Create a new playlist"
                            icon={AddIcon}
                        />
                    )}
                </ActionButtons>
                <FullFilter
                    data={playlistFilters as FilterDimensionsData}
                    filters={filters}
                    tagsOnly={true}
                    filterMethods={{
                        toggleFilter,
                        setAllFilters,
                        applyFilters: handleApplyFilters,
                        setFilter,
                    }}
                    search={{
                        query: searchQuery,
                        applyQuery: setSearchQuery,
                    }}
                    onOpenStateChange={setFiltersOpen}
                    render={() => (
                        <>
                            <Table
                                settings={{
                                    itemsName: 'playlists',
                                }}
                                columns={tableColumns}
                                data={data?.playlists.results || []}
                                dataLoading={{
                                    active: loading,
                                    text: 'Fetching your data, please wait...',
                                }}
                                dataError={{
                                    active: !!error,
                                    text: `There was an error: ${error?.message || 'network error'}`,
                                }}
                            />
                            {data && (
                                <TableFooter
                                    rows={data.playlists.paging.count}
                                    paging={{
                                        limit: data.playlists.paging.limit,
                                        page: data.playlists.paging.page,
                                    }}
                                    setPaging={setPaging}
                                    itemsName="playlists"
                                />
                            )}
                        </>
                    )}
                />
            </Layout>
            {openedModal && openedModal === PlaylistModalVariants.add && (
                <AddPlaylistModal
                    setModal={setOpenedModal}
                    currentChannel={channel}
                />
            )}
            {
                openedModal
                && openedModal === PlaylistModalVariants.export
                && (
                    <PlaylistExportModal<PlaylistModalVariants>
                        setModal={setOpenedModal}
                        playlistInfo={playlistToExport}
                        getFilter={() => getQueryVariables().filter}
                    />
                )
            }
        </>
    );
};

export default ChannelPlaylistsPage;
